@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: Poppins !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: Poppins !important;
}

.zindex {
  z-index: 10000;
}

.coverphotoholder {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.333),
      rgba(70, 165, 117, 0.648)
    ),
    url("https://firebasestorage.googleapis.com/v0/b/sclverse.appspot.com/o/predefs%2Fcoverphoto.jpg?alt=media&token=447e7c08-19fd-4d9f-ac87-679bd0039793");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #1ef26f transparent rgb(9, 233, 136) transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #46A576;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 10px;
}

.single-digit-input::-webkit-outer-spin-button,
.single-digit-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.single-digit-input[type="number"] {
  -moz-appearance: textfield;
}

.custom-outline:focus{
  outline: 0px solid rgba(255, 255, 255, 0.678);

}

.single-digit-input :focus{
  outline: 2px solid rgba(230, 5, 103, 0.678);



}

.receipt{

  background-image: url("https://schoolversedata.com/static/media/logo.0b294d0a6d0016241696.png");
  object-fit: cover;
  background-repeat: no-repeat;
} 
 
input:focus {
outline: #46A576;
}


::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 2px solid #f1f1f1; /* creates padding around thumb */
}


.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(144 97 249 / var(--tw-text-opacity));
}
hr{
  background-color: #646464;
}



.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(144 97 249 / var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(126 58 242 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 58 242 / var(--tw-bg-opacity));
}
.hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgb(85 33 181 / var(--tw-text-opacity));
}
.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(108 43 217 / var(--tw-bg-opacity));
}

hr{
background-color: black;
   height: 1px !important;
  width: 100% !important;
   margin-top: 10px !important;
   margin-bottom: 10px !important;

}